// Selectors ==========================================================================================
// Main
let main = document.querySelector("main");
// Sidebar btn toggler
let myOffcanvasButton = document.querySelector(".my-offcanvas-btn");
// Sidebar
let mySidebar = document.querySelector(".my-sidebar");
// Sidebar btn collapse-patients
let mySidebarCollapseTgrPatients = document.querySelector('[data-bs-target="#patients-collapse"]');
// Sidebar btn collapse-clinics
let mySidebarCollapseTgrClinics = document.querySelector('[data-bs-target="#clinics-collapse"]');
// Sidebar btn collapse-providers
let mySidebarCollapseTgrProviders = document.querySelector('[data-bs-target="#providers-collapse"]');
// Sidebar collapse-patients
let mySidebarCollapsePatients = document.getElementById("patients-collapse");
// Sidebar collapse-clinics
let mySidebarCollapseClinics = document.getElementById("clinics-collapse");
// Sidebar collapse-providers
let mySidebarCollapseProviders = document.getElementById("providers-collapse");
// Arrow icon-patients
let myIconArrowPatients = mySidebarCollapseTgrPatients.querySelector('.my-icon-arrow');
// Arrow icon-clinics
let myIconArrowClinics = mySidebarCollapseTgrClinics.querySelector('.my-icon-arrow');
// Arrow icon-providers
let myIconArrowProviders = mySidebarCollapseTgrProviders.querySelector('.my-icon-arrow');
// Content
let myContent = document.querySelector(".my-content");

// Sidebar toggle =====================================================================================
myOffcanvasButton.addEventListener("click", () => {
    // Apply active class to main (there is a main.active class in the css)
    main.classList.toggle("active");
    // Remove shadow
    mySidebar.classList.toggle("shadow");
})

// Patients collapse ==================================================================================
// Sidebar collapse memory ----------------------------------------------------------------------------
// Create instance of collapse
let sideBarPatientsCollapseBS = bootstrap.Collapse.getOrCreateInstance(mySidebarCollapsePatients, {
    toggle: false           // Collapse is not expanded when invoked/created.
});

// Read/remember last user collapse
let sidebarPatientsConfig = localStorage.getItem("sidebarPatientsCollapse") || "0";

// Set user last collapse
if (sidebarPatientsConfig == "1") {
    mySidebarCollapsePatients.classList.add("show");                  // Collapse expanded directly with no animation. .show() has an animation.
    myIconArrowPatients.style.transition = "none";                    // Shows directly the arrow icon expanded.
    mySidebarCollapseTgrPatients.setAttribute('aria-expanded', true); // For the arrow icon.
    localStorage.setItem("sidebarPatientsCollapse", "1");             // Save user config in local.
} else {
    localStorage.setItem("sidebarPatientsCollapse", "0");
}

// Collapse functionality
mySidebarCollapseTgrPatients.addEventListener("click", () => {
    myIconArrowPatients.style.transition = "all 0.3s ease";                // Returns to original transition.

    if (mySidebarCollapsePatients.classList.contains("show")) {
        sideBarPatientsCollapseBS.hide();
        mySidebarCollapseTgrPatients.setAttribute('aria-expanded', false); // For the arrow icon.
        localStorage.setItem("sidebarPatientsCollapse", "0");
    } else {
        sideBarPatientsCollapseBS.show();
        mySidebarCollapseTgrPatients.setAttribute('aria-expanded', true);  // For the arrow icon.
        localStorage.setItem("sidebarPatientsCollapse", "1");
    }
})

// Sidebar selection memory ---------------------------------------------------------------------------
// Select all link (menu options)
let menuSelectionPatients = mySidebarCollapsePatients.querySelectorAll("a:not(.my-group-header)")

menuSelectionPatients.forEach(link => {
    // Set user last selection (does not matter the order here as the page is reloaded when clicked on a link)
    if (link.innerText == localStorage.getItem("menuSelectedLinkPatients")) {
        link.classList.add('active');
    }

    // Event for menu selection
    link.addEventListener('click', (e) => {         // IMPORTANT! arrow-functions do not support THIS, should use (e) or use function (...) to use THIS.
        // Store menu selection
        localStorage.setItem("menuSelectedLinkPatients", e.target.innerText);
    })
});

// Clinics collapse ====================================================================================
// Sidebar collapse memory-patients --------------------------------------------------------------------
// Create instance of collapse
let sideBarClinicsCollapseBS = bootstrap.Collapse.getOrCreateInstance(mySidebarCollapseClinics, {
    toggle: false           // Collapse is not expanded when invoked/created.
});

// Read/remember last user collapse
let sidebarClinicsConfig = localStorage.getItem("sidebarClinicsCollapse") || "0";

// Set user last collapse
if (sidebarClinicsConfig == "1") {
    mySidebarCollapseClinics.classList.add("show");                  // Collapse expanded directly with no animation. .show() has an animation.
    myIconArrowClinics.style.transition = "none";                    // Shows directly the arrow icon expanded.
    mySidebarCollapseTgrClinics.setAttribute('aria-expanded', true); // For the arrow icon.
    localStorage.setItem("sidebarClinicsCollapse", "1");             // Save user config in local.
} else {
    localStorage.setItem("sidebarClinicsCollapse", "0");
}

// Collapse functionality
mySidebarCollapseTgrClinics.addEventListener("click", () => {
    myIconArrowClinics.style.transition = "all 0.3s ease";                // Returns to original transition.

    if (mySidebarCollapseClinics.classList.contains("show")) {
        sideBarClinicsCollapseBS.hide();
        mySidebarCollapseTgrClinics.setAttribute('aria-expanded', false); // For the arrow icon.
        localStorage.setItem("sidebarClinicsCollapse", "0");
    } else {
        sideBarClinicsCollapseBS.show();
        mySidebarCollapseTgrClinics.setAttribute('aria-expanded', true);  // For the arrow icon.
        localStorage.setItem("sidebarClinicsCollapse", "1");
    }
})

// Sidebar selection memory ----------------------------------------------------------------------------
// Select all link (menu options)
let menuSelectionClinics = mySidebarCollapseClinics.querySelectorAll("a:not(.my-group-header)")

menuSelectionClinics.forEach(link => {
    // Set user last selection (does not matter the order here as the page is reloaded when clicked on a link)
    if (link.innerText == localStorage.getItem("menuSelectedLinkClinics")) {
        link.classList.add('active');
    }

    // Event for menu selection
    link.addEventListener('click', (e) => {         // IMPORTANT! arrow-functions do not support THIS, should use (e) or use function (...) to use THIS.
        // Store menu selection
        localStorage.setItem("menuSelectedLinkClinics", e.target.innerText);
    })
});

// Providers collapse ====================================================================================
// Sidebar collapse memory-patients --------------------------------------------------------------------
// Create instance of collapse
let sideBarProvidersCollapseBS = bootstrap.Collapse.getOrCreateInstance(mySidebarCollapseProviders, {
    toggle: false           // Collapse is not expanded when invoked/created.
});

// Read/remember last user collapse
let sidebarProvidersConfig = localStorage.getItem("sidebarProvidersCollapse") || "0";

// Set user last collapse
if (sidebarProvidersConfig == "1") {
    mySidebarCollapseProviders.classList.add("show");                  // Collapse expanded directly with no animation. .show() has an animation.
    myIconArrowProviders.style.transition = "none";                    // Shows directly the arrow icon expanded.
    mySidebarCollapseTgrProviders.setAttribute('aria-expanded', true); // For the arrow icon.
    localStorage.setItem("sidebarProvidersCollapse", "1");             // Save user config in local.
} else {
    localStorage.setItem("sidebarProvidersCollapse", "0");
}

// Collapse functionality
mySidebarCollapseTgrProviders.addEventListener("click", () => {
    myIconArrowProviders.style.transition = "all 0.3s ease";                // Returns to original transition.

    if (mySidebarCollapseProviders.classList.contains("show")) {
        sideBarProvidersCollapseBS.hide();
        mySidebarCollapseTgrProviders.setAttribute('aria-expanded', false); // For the arrow icon.
        localStorage.setItem("sidebarProvidersCollapse", "0");
    } else {
        sideBarProvidersCollapseBS.show();
        mySidebarCollapseTgrProviders.setAttribute('aria-expanded', true);  // For the arrow icon.
        localStorage.setItem("sidebarProvidersCollapse", "1");
    }
})

// Sidebar selection memory ----------------------------------------------------------------------------
// Select all link (menu options)
let menuSelectionProviders = mySidebarCollapseProviders.querySelectorAll("a:not(.my-group-header)")

menuSelectionProviders.forEach(link => {
    // Set user last selection (does not matter the order here as the page is reloaded when clicked on a link)
    if (link.innerText == localStorage.getItem("menuSelectedLinkProviders")) {
        link.classList.add('active');
    }

    // Event for menu selection
    link.addEventListener('click', (e) => {         // IMPORTANT! arrow-functions do not support THIS, should use (e) or use function (...) to use THIS.
        // Store menu selection
        localStorage.setItem("menuSelectedLinkProviders", e.target.innerText);
    })
});

// Reset memory when click other pages =================================================================
let homeLink = document.querySelector('a[href*="index"]')
homeLink.addEventListener("click", () => {
    localStorage.removeItem("sidebarPatientsCollapse")
    localStorage.removeItem("sidebarClinicsCollapse")
    localStorage.removeItem("sidebarProvidersCollapse")
    localStorage.removeItem("menuSelectedLinkPatients")
    localStorage.removeItem("menuSelectedLinkClinics")
})

let patientsLinks = document.querySelectorAll('a[href*="patients"]')
patientsLinks.forEach(link => {
    link.addEventListener("click", ()=> {
        localStorage.removeItem("menuSelectedLinkClinics")
    })
})

let clinicsLinks = document.querySelectorAll('a[href*="clinics"]')
clinicsLinks.forEach(link => {
    link.addEventListener("click", ()=> {
        localStorage.removeItem("menuSelectedLinkPatients")
    })
})

let providersLinks = document.querySelectorAll('a[href*="providers"]')
providersLinks.forEach(link => {
    link.addEventListener("click", ()=> {
        localStorage.removeItem("menuSelectedLinkPatients")
    })
})

// User name after login ===============================================================================
let username = document.getElementById('username');
username.innerText = localStorage.getItem("username");

// ENV variables testing ===============================================================================
console.log("Using env variable: ", process.env.SELFHELP_API);
